export const getApplicationConfig = async () => {
  try {
    const configUrl = `${window.location.origin}/applicationConfig.json`;
    const response = await fetch(configUrl);

    if (!response.ok) {
      throw new Error(`Response status ${response.status}`);
    }

    const responseBody = await response.json();

    if (!responseBody.msal.mode) {
      responseBody.msal.mode = "legacy";
    }

    return responseBody;
  } catch (error) {
    console.error("Error fetching application configuration:", error);
  }
};
