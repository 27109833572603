import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.scss";
import App from "./App.js";

import { getApplicationConfig } from "./components/functions/config.js";
import { PublicClientApplication, LogLevel } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

const initializeMsal = async (config) => {
  try {
    const msalInstance = new PublicClientApplication({
      auth: {
        clientId: config.msal.clientId,
        authority:
          config.msal.mode === "legacy"
            ? `https://login.microsoftonline.com/${config.msal.authority}`
            : config.msal.authority,
        redirectUri: "/",
        protocolMode:
          config.msal.mode === "legacy" ? "AAD" : config.msal.protocolMode,
        knownAuthorities:
          config.msal.mode === "legacy" ? [] : [config.msal.authority],
      },
      cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
      },
      system: {
        loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
            if (containsPii) {
              return;
            }
            switch (level) {
              case LogLevel.Error:
                console.error(message);
                return;
              case LogLevel.Info:
                console.info(message);
                return;
              case LogLevel.Verbose:
                console.debug(message);
                return;
              case LogLevel.Warning:
                console.warn(message);
                return;
              default:
                return;
            }
          },
        },
      },
    });

    return msalInstance;
  } catch (error) {
    console.error("Error initializing MSAL:", error);
    return null;
  }
};

const startApp = async () => {
  const config = await getApplicationConfig();

  if (!config) {
    console.error("No application configuration");
    return;
  }

  document.title = `Hej ${config.customer.companyShort}`;

  const msalInstance = await initializeMsal(config);

  if (!msalInstance) {
    console.error("MSAL could not be initialized.");
    return;
  }

  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </React.StrictMode>
  );
};

startApp();
